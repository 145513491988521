<template>
    <div>
        <div v-if="preloading">
            <button class="btn btn-secondary"><i class="fas fa-cog fa-spin" /> {{ $t('global.loading') }}...</button>
        </div>

        <div v-if="!preloading && redactor && redactorWritings">
            <div v-if="redactor && redactor.user" class="mt-3">
                <div v-if="isAdmin" class="mb-3">
                    <router-link to="/admin/redactor">
                        <i class="fas fa-arrow-circle-left" /> Revenir à l'admin
                    </router-link>
                </div>

                <div class="row">
                    <div class="col-md-6 col-lg-6 col-xl-2">
                        <Balance
                            :amount="parseInt(redactor.word_count)"
                            title=""
                            :subtitle="$t('redactor.nbWordsWriter')"
                            icon="fa fa-pen-nib"
                        />
                    </div>
                    <template v-for="price in redactorPrices">
                        <div :key="price.id" class="col-md-6 col-lg-6 col-xl-2">
                            <Balance
                                :amount="Number.parseFloat(price.price)"
                                title=""
                                :subtitle="`Prix pour 100 mots ${price.flag}`"
                                icon="fa fa-euro-sign"
                            />
                        </div>
                    </template>
                </div>
            </div>

            <div class="box">
                <b-tabs size="is-medium" class="block">
                    <b-tab-item label="Dashboard" icon="tachometer-alt">
                        <b-loading v-model="dataIsLoading" />
                        <div class="row">
                            <p class="col-12">{{ $t('redactor.summary-activity') }} :</p>
                            <div class="col-12 col-md-6 row">
                                <div class="form-group mb-3 col-12 col-md-6">
                                    <label for="date_from">{{ $t('redactor.from') }}</label>
                                    <input
                                        id="date_from"
                                        v-model="dateFrom"
                                        type="date"
                                        class="form-control"
                                        required
                                    />
                                </div>
                                <div class="form-group mb-3 col-12 col-md-6">
                                    <label for="date_to">{{ $t('redactor.to') }}</label>
                                    <input id="date_to" v-model="dateTo" type="date" class="form-control" required />
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-5">
                            <div class="col-md-12 col-lg-12 col-xl-4">
                                <DashboardKPI
                                    div-class="col-md-12 mb-2"
                                    icon-size="icon-lg"
                                    :number="redactorWritings.total"
                                    :label="$t('redactor.orders-done')"
                                    icon-class="fas fa-file-alt"
                                    background="bg-redactor-kpi1"
                                />

                                <DashboardKPI
                                    div-class="col-md-12 mb-2"
                                    icon-size="icon-lg"
                                    :number="redactorWritings.words"
                                    :label="$t('redactor.words-done')"
                                    icon-class="fa fa-quote-right"
                                    background="bg-redactor-kpi2"
                                />

                                <DashboardKPI
                                    div-class="col-md-12 mb-2"
                                    icon-size="icon-lg"
                                    :number="redactorWritings.amount / 100 + '€'"
                                    :label="$t('redactor.earnings')"
                                    icon-class="fas fa-money-check-alt"
                                    background="bg-redactor-kpi3"
                                />
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-8 h-100">
                                <div
                                    v-if="redactorWritings.writingsPerDay.length > 0"
                                    class="p-2 w-100"
                                    style="max-height: 400px"
                                >
                                    <RedactorWordsCountFollowUp
                                        ref="wordCountManager"
                                        :redactor="redactor"
                                        :orders="redactorWritings.writingsPerDay"
                                        :date-from="dateFrom"
                                        :date-to="dateTo"
                                    />
                                </div>
                            </div>
                        </div>
                        <TabRedactorSummaryOrders :paginated-data="logs" />
                    </b-tab-item>
                    <b-tab-item v-if="isAdmin" label="Actions" icon="bolt">
                        <div class="d-flex">
                            <NlCard title="Rédaction" grid-classes="col-md-12 col-xl-4">
                                <div class="form-group">
                                    <label for="word_count">Modifier le nombre de mot :</label>
                                    <input
                                        id="word_count"
                                        v-model="redactor.word_count"
                                        class="form-control"
                                        type="text"
                                    />
                                </div>

                                <p class="mb-1">Type de rédaction :</p>
                                <div class="ml-4 mb-4">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            id="preorders"
                                            v-model="redactor.preorders"
                                            type="checkbox"
                                            class="custom-control-input"
                                        />
                                        <label class="custom-control-label" for="preorders">Précommandes</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            id="spots"
                                            v-model="redactor.spots"
                                            type="checkbox"
                                            class="custom-control-input"
                                        />
                                        <label class="custom-control-label" for="spots">Spots</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            id="orders"
                                            v-model="redactor.orders"
                                            type="checkbox"
                                            class="custom-control-input"
                                        />
                                        <label class="custom-control-label" for="orders">Commandes</label>
                                    </div>
                                </div>

                                <button class="btn btn-primary" :disabled="loading" @click.prevent="doSaveRedactor()">
                                    Sauvegarder
                                </button>

                                <div class="mt-5">
                                    <button
                                        :class="[redactor.actived_at ? 'btn-danger' : 'btn-success']"
                                        class="btn"
                                        @click.prevent="toggleRedactorStatus()"
                                    >
                                        <span v-if="redactor.actived_at">
                                            <b-icon icon="ban" />
                                            <span class="ml-2">Désactiver la fonction rédacteur</span>
                                        </span>
                                        <span v-else>
                                            <b-icon icon="check" />
                                            <span class="ml-2">Activer la fonction rédacteur</span>
                                        </span>
                                    </button>
                                </div>
                            </NlCard>

                            <NlCard title="Prix & langues" grid-classes="col-md-12 col-xl-4">
                                <div class="form-group">
                                    <div class="mb-1">Langue du rédacteur :</div>
                                    <span v-for="(price, indexLang) in redactor.prices" :key="price.id">
                                        <div
                                            class="badge badge-primary p-2 mr-4"
                                            @click.prevent="removeLang(indexLang)"
                                        >
                                            {{ langs.find(lang => lang.code === price.lang).name }}
                                            <i class="fas fa-trash pl-2" />
                                        </div>
                                    </span>
                                </div>

                                <div class="form-group">
                                    <label for="language">Ajouter une nouvelle langue :</label>
                                    <select id="language" v-model="selectedLang" class="form-control">
                                        <option v-if="redactorLangs.length <= 0" disabled>
                                            Toutes les langues ont été ajoutées
                                        </option>
                                        <option v-else disabled value="-1">Selectionnez une langue</option>

                                        <template v-for="lang in redactorLangs">
                                            <option :key="lang.code" :value="lang.code">
                                                {{ lang.name }}
                                            </option>
                                        </template>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="price">Définir le prix aux 100 mots:</label>
                                    <input id="price" v-model="langPrice" class="form-control" type="number" />
                                </div>

                                <button class="btn btn-primary" :disabled="loading" @click.prevent="savePrice()">
                                    Sauvegarder
                                </button>
                            </NlCard>

                            <NlCard title="Blacklists & review" grid-classes="col-md-12 col-xl-4">
                                <div class="form-group">
                                    <div class="mb-1">Blacklist du rédacteur :</div>
                                    <span
                                        v-if="redactor.blacklisted_subjects && redactor.blacklisted_subjects.length > 0"
                                    >
                                        <span
                                            v-for="(refusal, index) in redactor.blacklisted_subjects"
                                            :key="refusal.id"
                                            class="m-1 tag"
                                        >
                                            {{ refusal.localized_translations.name }}
                                            <span class="px-2 py-1" @click.prevent="removeBlacklist(index)">
                                                <i class="fas fa-trash" />
                                            </span>
                                        </span>
                                    </span>
                                    <span v-else>
                                        <div class="p-2">
                                            {{ $t('redactor.no-info') }}
                                        </div>
                                    </span>
                                </div>

                                <div class="form-group">
                                    <label for="groups">Ajouter des filtres de rédaction :</label>
                                    <select
                                        id="groups"
                                        v-model="blacklist"
                                        class="form-control"
                                        @change="updateBlacklist()"
                                    >
                                        <option v-for="cat in groups" :key="cat.id" :value="cat">
                                            {{ cat.name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="groups">Pourcentage de chance de relecture :</label>
                                    <div class="d-flex align-items-center">
                                        <b-slider
                                            v-model="redactor.review_percent"
                                            :max="100"
                                            :min="0"
                                            :step="10"
                                            size="is-medium"
                                        >
                                            <template v-for="val in [0, 10, 30, 50, 70, 90]">
                                                <b-slider-tick :key="val" :value="val">
                                                    {{ val }}
                                                </b-slider-tick>
                                            </template>
                                        </b-slider>

                                        <button
                                            class="btn btn-dark btn-sm ml-4"
                                            :disabled="loading"
                                            @click.prevent="doSaveRedactor()"
                                        >
                                            <i class="fas fa-save" />
                                        </button>
                                    </div>
                                </div>
                            </NlCard>
                        </div>

                        <div class="row">
                            <NlCard title="Créditer / débiter un rédacteur" grid-classes="col-md-12 col-xl-4">
                                <CreditRedactorForm />
                            </NlCard>
                        </div>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<style>
    .bg-redactor-kpi1 {
        background-color: #f9bc13;
    }

    .bg-redactor-kpi2 {
        background-color: #c5b932;
    }

    .bg-redactor-kpi3 {
        background-color: #5cb270;
    }
</style>

<script>
    import { mapActions, mapState } from 'vuex'
    import NlCard from '@/components/UI/NlCard'
    import CreditRedactorForm from '@/components/Redactor/Forms/CreditRedactorForm'

    export default {
        name: 'RedactorDetails',
        components: { CreditRedactorForm, NlCard },
        data: function () {
            return {
                redactor_id: null,
                ordersPerDay: [],
                redactorPrices: [],
                totalOrders: 0,
                totalWords: 0,
                totalAmount: 0,

                blacklist: null,
                langPrice: null,
                selectedLang: null,

                //UI
                preloading: false,
                dataIsLoading: false,
                timeout: null,
                isAdmin: this.$store.state.auth.user.role === 'admin',

                loading: false,

                redactorLangs: [],
                langs: [
                    {
                        code: 'fr_fr',
                        name: 'Français'
                    },
                    {
                        code: 'en_us',
                        name: 'English'
                    }
                ]
            }
        },
        watch: {
            dateFrom() {
                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                this.timeout = setTimeout(this.getOrdersSummaryByPeriod, 1000)
            },
            dateTo() {
                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                this.timeout = setTimeout(this.getOrdersSummaryByPeriod, 1000)
            }
        },
        computed: {
            ...mapState('redactors', {
                redactor: state => state.redactor,
                logs: state => state.logs,
                redactorWritings: state => state.redactorWritring
            }),
            dateFrom: {
                get() {
                    return this.$store.state.redactors.date_from
                },
                set(value) {
                    this.$store.commit('redactors/setDateFrom', value)
                }
            },
            dateTo: {
                get() {
                    return this.$store.state.redactors.date_to
                },
                set(value) {
                    this.$store.commit('redactors/setDateTo', value)
                }
            }
        },
        created() {
            this.redactor_id = this.$route.query.id

            this.axios.get('/crud/groups?orderBy=name').then(response => {
                this.groups = response.data.groups
            })
            this.getOrdersSummaryByPeriod()
        },
        methods: {
            ...mapActions('redactors', {
                getRedactorWritings: 'getRedactorWritings',
                saveRedactor: 'saveRedactor',
                saveRedactorBlacklists: 'saveRedactorBlacklists',
                saveRedactorPrice: 'saveRedactorPrice',
                removeRedactorBlacklists: 'removeRedactorBlacklists',
                removeRedactorLangs: 'removeRedactorLangs'
            }),

            doSaveRedactor() {
                this.loading = true

                this.saveRedactor()
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Le rédacteur a bien été mis à jour'
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            toggleRedactorStatus() {
                if (this.redactor.actived_at) {
                    this.redactor.actived_at = null
                } else {
                    this.redactor.actived_at = this.moment().format('YYYY-MM-DD')
                }

                this.doSaveRedactor()
            },

            removeLang(index) {
                let lang = this.redactor.prices[index]

                this.removeRedactorLangs(lang)
                    .then(() => {
                        this.redactor.prices.splice(index, 1)
                        this.updateLangs()
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'La langue du rédacteur a bien été mise à jour'
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            savePrice() {
                let isLangAlreadyInPrices = this.redactor.blacklisted_subjects.find(
                    lang => lang.id === this.langPrice.id
                )

                if (isLangAlreadyInPrices) {
                    this.langPrice = null
                    this.$store.dispatch('toast', {
                        type: 'info',
                        title: 'Cette langue est déjà présente'
                    })
                } else {
                    this.loading = true

                    this.saveRedactorPrice({
                        lang: this.selectedLang,
                        price: this.langPrice * 100
                    })
                        .then(data => {
                            if (!this.redactor.prices) {
                                this.redactor.prices = []
                            }

                            this.redactor.prices.push(data.price)
                            this.updateLangs()

                            this.$store.dispatch('toast', {
                                type: 'success',
                                title: 'La langue a bien été ajoutée'
                            })
                        })
                        .catch(e => {
                            this.$store.dispatch('toastError', e)
                        })
                        .finally(() => {
                            this.langPrice = null
                            this.loading = false
                        })
                }
            },

            removeBlacklist(index) {
                let blacklist = this.redactor.blacklisted_subjects[index]
                this.removeRedactorBlacklists(blacklist)
                    .then(() => {
                        this.redactor.blacklisted_subjects.splice(index, 1)
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'La blacklist du rédacteur a bien été mise à jour'
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            updateBlacklist() {
                let isSubjectAlreadyInBlacklist = this.redactor.blacklisted_subjects.find(
                    blacklist => blacklist.id === this.blacklist.id
                )

                if (isSubjectAlreadyInBlacklist) {
                    this.blacklist = null
                    this.$store.dispatch('toast', {
                        type: 'info',
                        title: 'Ce sujet est déjà blacklist'
                    })
                } else {
                    this.loading = true

                    this.saveRedactorBlacklists(this.blacklist.id)
                        .then(data => {
                            if (!this.redactor.blacklisted_subjects) {
                                this.redactor.blacklisted_subjects = []
                            }

                            this.redactor.blacklisted_subjects.push(data.blacklist)

                            this.$store.dispatch('toast', {
                                type: 'success',
                                title: 'La blacklist du rédacteur a bien été mise à jour'
                            })
                        })
                        .catch(e => {
                            this.$store.dispatch('toastError', e)
                        })
                        .finally(() => {
                            this.blacklist = null
                            this.loading = false
                        })
                }
            },

            getOrdersSummaryByPeriod: function () {
                this.preloading = true

                this.getRedactorWritings({
                    id: this.redactor_id,
                    date_from: this.dateFrom,
                    date_to: this.dateTo
                })
                    .then(() => {
                        this.updateLangs()
                    })
                    .catch(error => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: error.response.data.message
                        })
                    })
                    .finally(() => {
                        this.setTitle(
                            `${this.$t('redactor.title')} : ${this.redactor.user.firstname} ${
                                this.redactor.user.lastname
                            }`
                        )
                        this.preloading = false
                    })
            },
            updateLangs: function () {
                this.redactorLangs = []
                for (var i = 0; i < this.langs.length; i++) {
                    var lang = this.redactor.prices.find(price => price.lang === this.langs[i].code)

                    if (!lang) {
                        this.redactorLangs.push(this.langs[i])
                    }
                }

                this.redactorPrices = this.redactor.prices.map(price => {
                    return {
                        id: price.id,
                        price: (price.price / 100).toFixed(2),
                        lang: price.lang,
                        flag: `<span title='${
                            price.lang == 'fr_fr' ? 'fr' : 'us'
                        }' class='ml-2 flag-icon flag-icon-squared flag-icon-${
                            price.lang == 'fr_fr' ? 'fr' : 'us'
                        }'></span>`
                    }
                })
            }
        },
        mounted() {}
    }
</script>
